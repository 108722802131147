import React from 'react';
import './css/App.scss';
import Project from './page-components/Project.js';
import Home from './page-components/Home.js';
import Competence from './page-components/Competence.js';
import Footer from './page-components/Footer.js';
import Services from './page-components/Services';
import {Row, Col } from "react-bootstrap";
import Intro from './page-components/Intro.js';
import Contact from './page-components/Contact.js';

function App() {
    return (
    <div>
      <div className="body container-fluid">
        <Home/>
        <Intro/>
        <Competence/>
        <Services/>
        <Project/>    
        <Contact/>  
        <Footer/> 
      </div>
    </div>
  );
}

export default App;
