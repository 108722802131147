import React from 'react';
import './../css/Competence.scss';
import {Col, Row} from 'react-bootstrap';
import Tag from '../components/Tag';

function Competence() {     
    return (  
        <div id="skills">
           <Row className="cs-block">
            <div className="title-overlay">
                <div className="overlay"></div>
                <div className="text">COMPÉTENCES</div>
            </div>
             <Row>
            <Col className="col-12 col-xl-6 pd-2">
                <div className="cat">Principales </div>
                <Tag text="React"></Tag>
                <Tag text="Redux-Saga"></Tag>
                <Tag text="C#"></Tag>
                <Tag text="EntityFramework"></Tag>
                <Tag text="SQL Server"></Tag>
                <Tag text="AWS"></Tag>
                <br></br>
                <img src="pictures/logo-react-redux.png" className="logo"></img>
                <img src="pictures/logo-csharp.png" className="logo"></img>
                <img src="pictures/logo-entityframework.png" className="logo"></img>
                <p>
                <img src="pictures/logo-sql-server.png" className="logo logo-sql-server"></img>
                <img src="pictures/logo-aws.png" className="logo"></img></p>
            </Col>

            <Col className="col-12 col-xl-6 pd-2">
                <div className="cat">Autres langages </div>
                <Tag text="ASP.Net MVC"></Tag>
                <Tag text="ASP.Net WebForm"></Tag>
                <Tag text="JavaScript"></Tag>
                <Tag text="jQyery"></Tag>
                <Tag text="bootstrap"></Tag>
                <Tag text="XML"></Tag>
                <Tag text="REST"></Tag>
                <Tag text="AJAX"></Tag>
                <Tag text="Excel VBA"></Tag>
                <Tag text="VB.Net"></Tag>
                <Tag text="Winform"></Tag>
                
                <div className="cat mt-4">Outils </div>  
                <Tag text="Méthodologie Agile"></Tag>
                <Tag text="Visual Studio"></Tag>
                <Tag text="VS Code"></Tag>
                <Tag text="IntelliJ"></Tag>
                <Tag text="PostgreSQL"></Tag>   
                <Tag text="Team Foundation Services"></Tag>
                <Tag text="SVN"></Tag>
                <Tag text="Git"></Tag>
                <Tag text="Jira"></Tag>       
            </Col>    
            </Row>
        </Row>
        </div>
    )
}

export default Competence;