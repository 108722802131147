import React from 'react';
import './../css/ProjectCard.scss';

function ProjectCard(props) { 
    return (
      <div className={`project-card ${props.selected ? "selected" : ""}`}
            onClick={ () => props.onclick()}>
            <div className="text">{props.index}. {props.title}</div>
      </div>
    )    
}

export default ProjectCard;