import React from 'react';
import './../css/Header.scss';
import {Col,  Row} from "react-bootstrap";


function scrollTo(elementId) {
      let element = document.getElementById(elementId);
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop
      });
    }

function Header() {    
      
    return (    

    <div className="header d-none d-lg-block">
      
            <Col className="text-center header-bar">
                  <button className="item" onClick={() => {scrollTo("intro")}}>Bio</button>
                  <button className="item" onClick={() => {scrollTo("skills")}}>Compétences</button>
                  <button className="item" onClick={() => {scrollTo("services")}}>Services</button>
                  <button className="item" onClick={() => {scrollTo("project")}}>Conduite de projet</button>
                  <button className="item" onClick={() => {scrollTo("contact")}}>Me contacter</button>
            </Col>
   
    </div>
    )    
}

export default Header;