import React from 'react';
import './../css/Home.scss';
import {Col, Row } from "react-bootstrap";
import { ArrowDown } from 'react-bootstrap-icons';
import Header from '../page-components/Header';

function scrollTo(elementId) {
  let element = document.getElementById(elementId);
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.offsetTop
  });
}

function Profil() {     
    return (    

<div className="header-profile row">  
  <Header></Header>
  <div className="overlay"></div>

  <Col className="col-12 text-center">     
    <div className="text">
      <h1 className="name">Benoit KOVARZ</h1>
      <h1 className="job">Développeur Web Full-Stack - Freelance</h1>    
      {/* <span className="remote">Remote only !</span>   */}
      <div className="infos text-center">
        <div className="links">
          <a href="https://www.linkedin.com/in/benoit-kovarz/" target="_blank" rel="noopener noreferrer">
            <div className="link-icon linkedin">
            </div>
          </a>
          <a href="https://www.malt.fr/profile/benoitkovarz" target="_blank" rel="noopener noreferrer">
            <div className="link-icon malt">
            </div>
          </a>
        </div>
      </div>
      <div className="text-center">
        <div className="btn-next" onClick={() => scrollTo("intro")}>
          <ArrowDown></ArrowDown>
        </div>
      </div>
    </div>
  </Col>

</div>
    ) 
}

export default Profil;