import React from 'react';
import './../css/Footer.scss';
import {Col,  Row} from "react-bootstrap";

function Footer() {     
    return (    
    <div className="footer row">
      
        <div className="footer-background">
            <div className="overlay"></div>
            <Col className="text">  
                <Row>
                    <Col className="copyright col-12 col-sm-12 col-md-12 col-lg-6">
                        © Copyright Benoit Kovarz - 2020
                    </Col>
                    <Col className="legal col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="legal-text">
                            <p className="legal-title">Mentions légales</p>                           
                            <div>Benoit KOVARZ</div> 
                            <div>45 rue du vieux moulin - 57535 Marange-Silvange</div>                          
                            <div>SIRET 88863614900021</div>
                            <br></br>                            
                            <div>kovarz.benoit@hotmail.fr</div>
                            <div>06.84.36.92.29</div>                           
                        </div>
                    </Col>
               </Row>
            </Col>  
        </div>
       
        </div>
    )    
}

export default Footer;