import React , { useState } from 'react';
import './../css/Project.scss';
import {Col,  Row} from "react-bootstrap";
import ProjectCard from "../components/ProjectCard"

function step1Description(){
    return <div>
                <h3>1. Compréhension de votre besoin</h3>
                <br></br>
                <div><i>Un projet web réussi est un projet réfléchi en amont.</i></div>
                <br></br>
                 <div>Il est important de poser, au maximum, vos idées par écrit afin de s'assurer du contenu de chaque fonctionnalité, des workflows, des données que vous souhaitez recueillir et afficher.</div>
                 <div>Qui doit avoir accès à ces informations ? Pour quoi faire ?</div>
                 <div>Quelle est le degrès d'urgence de cette fonctionnalité ? Qu'apporte-t-elle réellement à mes utilisateurs ou clients ?</div>
                 <div>Faut-il conserver ces données pour éventuellement pouvoir les exploiter plus tard ?</div>
            </div> 
}

function step2Description(){
    return <div>
                <h3>2. Conception et création</h3>
                <br></br>
                <div><i>Une phase bien trop souvent négligée</i></div>
                <br></br>
                <div>La phase de conception est une étape indispensable, car elle définit la structure de votre site web. L'arborescence du site est primordiale pour hiérachiser vos contenus et les articuler de manière logique.</div>
                <br></br>
                <div>Les maquettes filaires permettent d'avoir une vue d'ensemble sur le contenu des pages, sans s'attarder sur les détails graphiques. Cela permet aussi de prévoir chacune des fonctionnalités, tout en y rajoutant les oublis non prévu de tête. </div>

            </div> 
            }

function step3Description(){
    return <div>
                <h3>3. Points d'avancement et démos</h3>
                <br></br>
                <div><i>Primordial pour un résultat optimal</i></div>
                <br></br>
                <div>Dans l'objectif d'être plus efficace et transparent, des points réguliers sont nécessaires. </div>
                <div>Loin du cycle en V qui faisait l'unanimité dans les années 2000 et contraint par des besoins de plus en plus changeant, il est parfois nécessaire d'effectuer des ajustements pendant la phase de réalisation.</div>
                <div>C'est avec bienveillance et logique que nous apporterons ces modifications cruciales pour votre activité.</div>
            </div> 
}

function step4Description(){
    return <div>
                <h3>4. Tests et mise en production</h3>
                <br></br>
                <div><i>La confiance n'empêche pas le contrôle</i></div>
                <br></br>
                <div>Il est important que le client participe à la phase de test. Vous allez pouvoir vérifier l'exactitude des résultats, mais aussi manipuler la plateforme en tant que premier utilisateur. Bien que vous soyez un élément clef du projet, vous allez enfin pouvoir vous glisser dans la peau de vos clients ou de vos utilisateurs et éventuellement y déceler les dernières finitions nécessaires. </div>
                <br></br>
                <div>Aprés votre validation, la production du code et tous les documents créés vous seront remis.</div>
            </div> 
}

function step5Description(){
    return <div>
                <h3>5. Suivi du livrable</h3>
                <br></br>
                <div><i>Un produit en perpétuelle maintenance</i></div>
                <br></br>
                <div>Malgrès une exécution parfaite des précédentes étapes, tout système informatique doit être maintenu dans le temps et en fonction de son usage.</div>
                <div>En guise de service après-vente, je m'engage à rester disponible au cours de la vie de votre produit pour toute correction et modification (dans la mesure du raisonnable).</div>
            </div> 
}

function IsSelected(selectedIndex, index)
{
    if(selectedIndex == index ) return true;
    return false;
}

function Project() {   
    
    const [description, setDescription] = useState(step1Description());
    const [selectedIndex, setSelectedIndex] = useState(1);
    return (  
        <div id="project">
            <Row className="cs-block">
                <div className="title-overlay">
                    <div className="overlay"></div>
                    <div className="text">CONDUITE DE PROJET WEB RÉUSSI</div>
                </div>
                <Row className="project-table">
                    <Col className="project-titles col-12 col-xl-4">
                        <ProjectCard index="1" 
                        title="Compréhension de votre besoin" 
                        selected={IsSelected(selectedIndex, 1)}
                        onclick={() => {
                            setSelectedIndex(1);
                            setDescription(step1Description())}}></ProjectCard>

                        <ProjectCard index="2"
                        title="Conception et création" 
                        selected={IsSelected(selectedIndex, 2)}
                        onclick={() => {
                            setSelectedIndex(2);
                            setDescription(step2Description())}}></ProjectCard>

                        <ProjectCard index="3" 
                        title="Points d'avancement et démos"
                        selected={IsSelected(selectedIndex, 3)}
                        onclick={() => {
                            setSelectedIndex(3);
                            setDescription(step3Description())}}></ProjectCard>

                        <ProjectCard index="4" 
                        title="Tests et mise en production"
                        selected={IsSelected(selectedIndex, 4)}
                        onclick={() => {
                            setSelectedIndex(4);
                            setDescription(step4Description())}}></ProjectCard>

                        <ProjectCard index="5" 
                        title="Suivi du livrable"
                        selected={IsSelected(selectedIndex, 5)}
                        onclick={() => {
                            setSelectedIndex(5);
                            setDescription(step5Description())}}></ProjectCard>      
                    </Col>

                    <Col className="project-description col-12 col-xl-8" id="project-description">
                        {description}
                    </Col>
            </Row>
            </Row>
        </div>
    )    
}

export default Project;