import React from 'react';
import './../css/Services.scss';
import {Col, Row} from 'react-bootstrap'

function WhatIDo() {     
    return (  
        <div className="services" id="services">
          <div className="cs-block row">
            <div className="title-overlay">
                <div className="overlay"></div>
                <div className="text">MES SERVICES</div>
            </div>
                 <Col className="text-left pd-1">
                    <div className="service">
                        <div className="subtitle">
                            Besoin de renfort dans vos équipes
                        </div>
                        <div className="description">
                            <ul>
                                <li>En régie, je renforcerai votre équipe et apporterai toutes mes connaissances techniques pour faire avancer le projet</li>
                            </ul>
                        </div>
                    </div>

                    <div className="service">
                        <div className="subtitle">
                            Création et conception de site web sur-mesure
                        </div>
                        <div className="description">
                            <ul>
                                <li>Vous avez une idée de business numérique mais vous ne savez pas la transformer en un produit exploitable </li>
                                <li>Besoin d'un accompagnement technique pour la création d'un POC de startup</li>
                                <li>Votre fichier excel est rempli de macros inmaintenables, vous souhaitez une équivalence fonctionnelle et au goût du jour</li>
                            </ul>
                        </div>
                    </div>

                    <div className="service">
                        <div className="subtitle">
                            Ajout de fonctionnalité
                        </div>
                        <div className="description">
                            <ul><li>Vous avez besoin d'une nouvelle page, d'un nouvel appel API, d'un autre export de données ou autres...</li></ul>
                        </div>
                    </div>

                    <div className="service">
                        <div className="subtitle">
                            Refonte
                        </div>
                        <div className="description">
                        <ul>
                            <li>Graphique : votre site est visuellement vieux et a besoin d'un rafraichissement, tout en conservant son contenu </li>                           
                            <li>Fonctionnelle : ses fonctionnalités existent mais ne correspondent plus à votre besoin d'aujourd'hui</li>
                        </ul>
                        </div>
                    </div>

                    <div className="service">
                        <div className="subtitle">
                            Correction de bug
                        </div>
                        <div className="description">
                           <ul><li>Ce bug existe depuis toujours ou vient d'être découvert. Il est grand temps de le corriger pour ne pas impacter vos résultats</li></ul>
                        </div>
                    </div>

                    <div className="service">
                        <div className="subtitle">
                            Un autre besoin
                        </div>
                        <div className="description">
                          <ul><li>  Merci de <a href="#contact" className="btn-contact-me">me contacter</a> afin d'échanger sur votre projet</li></ul>
                        </div>
                    </div>
                </Col>   
                <Col className="work-together-container d-none d-xl-block">
                    <div className="work-together"></div>
                </Col>  
                </div>
        </div>
    )
}


export default WhatIDo;