import React from 'react';
import './../css/Tag.scss';

function Tag(props) { 
    return (
    <div className="tag-container">
          <span className="tag-text"> {props.text}</span> 
    </div>
    )    
}


export default Tag;