import React from 'react';
import './../css/Contact.scss';
import {Col,  Row} from "react-bootstrap";

function Contact() {     
    return (    
    <div id="contact">
       
      <Row>
            <Col className="text-center">
                  <a href="mailto:kovarz.benoit@hotmail.fr"  className="btn-contact">ME CONTACTER</a>
            </Col>
       </Row>
    </div>
    )    
}

export default Contact;