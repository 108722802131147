import React from 'react';
import './../css/Intro.scss';
import {Col,  Row} from "react-bootstrap";
import Tag from "../components/Tag";

function Intro() {     
    return (    
      <div id="intro" className="intro "> 
        <Row className="cs-block">
        <div className="title-overlay">
            <div className="overlay"></div>
            <div className="text">SPÉCIALISÉ EN CRÉATION DE PLATEFORME WEB</div>
        </div>
      
        <Col className="col-12 col-xl-6 text-justify presentation">
          <h4>Courte bio : mon attrait pour le web</h4>
          <br></br>
          <p>J'ai découvert la programmation en classe de première alors que je poursuivais des études qui me promettaient un avenir dans le monde industriel.
          La programmation d'automate requiert du matériel onéreux et se base essentiellement sur de l'algorithmie.
          C'est en 3ième année de Licence et à la recherche d'un résultat visuel sur écran, que je me suis tourné vers la programmation informatique; plus particulièrement vers le web.
          </p><p>
          Diplomé d'un Master Génie Electronique et Informatique Industriel (promotion 2012 à Metz), avec une spécialité Mesures et Traitement de l'Information, j'ai ciblé chacun de mes stages et expériences profressionnelles vers un environnement web.
          </p>
          <p>
          Aujourd'hui, fort de plusieurs années d'expériences au sein de PME et startup, je suis capable d'élaborer un projet web de A à Z.
          Je dédie toute mon activité à la conception et l'amélioration continue de sites internet complexes, loin des CMS (Wordpress, Joomla, Drupal, Wix).
          </p>
        </Col>

        <Col className="col-12 col-xl-6">
          <Row>
            <Col className="photo-wrapper">
            <div className="photo">
              <div className="cache-misere-top"></div>
              <div className="cache-misere-bottom"></div>
            </div>
            </Col>
          </Row>         
        </Col>          
      </Row>
    </div> 
    )      
}

export default Intro;